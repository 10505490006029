<template>
    <div class="backdrop fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-opacity-50 bg-black" @click.self="emit('close')">
        <div class="add-product-modal-container bg-white px-8 py-4 text-base w-2/5">
            <h2 class="text-3xl font-bold">Add Product</h2>
            <hr />
            <div class="section my-2">
                <h2 class="text-2xl font-semibold my-2">General</h2>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Item Name <span class="text-red-500">*</span></label>
                    <input type="text" placeholder="Enter Item Name" class="border-solid border-2 w-3/5 px-3 py-2" v-model="addProductForm.itemName"/>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Category <span class="text-red-500">*</span></label>
                    <div class="w-3/5 flex items-center">
                        <select class="flex-1 border-solid border-2 px-3 py-2 cursor-pointer" v-model="addProductForm.category">
                            <option v-for="category in categories" :value="category.name" v-bind:key="category.name">{{category.name}}</option>
                            <option v-if="categories.length == 0" disabled>No categories available</option>
                        </select>
                        <i class="fas fa-plus ml-3 cursor-pointer" @click="addCategoryModalVisible = true"></i>
                    </div>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Item Code</label>
                    <input type="text" placeholder="Enter Item Code" class="border-solid border-2 w-3/5 px-3 py-2" v-model="addProductForm.itemCode" />
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Item Description</label>
                    <textarea placeholder="Enter Item Description" class="border-solid border-2 w-3/5 px-3 py-2" v-model="addProductForm.itemDesc"></textarea>
                </div>
            </div>
            <div class="section my-2">
                <h2 class="text-2xl font-semibold my-2">Stock Details</h2>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Amount <span class="text-red-500">*</span></label>
                    <input type="number" placeholder="Enter Amount of stock" min=0 class="border-solid border-2 w-3/5 px-3 py-2" v-model="addProductForm.stockAmt"/>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Unit</label>
                    <select class="w-3/5 border-solid border-2 px-3 py-2 cursor-pointer" v-model="addProductForm.stockUnit">
                        <option value="PCS" selected>Pieces (PCS)</option>
                        <option value="LTR">Litres (LTR)</option>
                    </select>
                </div>
            </div>
            <div class="section my-2">
                <h2 class="text-2xl font-semibold my-2">Pricing</h2>
                <div class="my-2 flex items-center justify-between">
                    <label for="">HSN Code</label>
                    <input type="text" placeholder="Enter HSN Code" min=0 class="border-solid border-2 w-3/5 px-3 py-2" v-model="addProductForm.hsnCode"/>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Sales Price <span class="text-red-500">*</span></label>
                    <div class="w-3/5 flex">
                        <span class="border-solid border-2 px-3 py-2 flex-1 flex">
                            <span class="mr-1">₹</span>
                            <input type="text" placeholder="Enter Sales Price" min=0 class="outline-none w-full" v-model="addProductForm.salesPrice" @keypress="isNumber($event)" />
                        </span>
                        <div @click="salesPriceTaxToggle = !salesPriceTaxToggle" :class="{'border-blue-300': salesPriceTaxToggle, 'border-yellow-300': !salesPriceTaxToggle}" class="border-solid border-2 flex items-center px-3 ml-1 cursor-pointer">
                            <span v-if="salesPriceTaxToggle">Incl. Tax</span>
                            <span v-else>Excl. Tax</span>
                        </div>
                    </div>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">Purchase Price <span class="text-red-500">*</span></label>
                    <div class="w-3/5 flex">
                        <span class="border-solid border-2 px-3 py-2 flex-1 flex">
                            <span class="mr-1">₹</span>
                            <input type="text" placeholder="Enter Purchase Price" min=0 class="outline-none w-full" v-model="addProductForm.purchasePrice" @keypress="isNumber($event)" />
                        </span>
                        <div @click="purchasePriceTaxToggle = !purchasePriceTaxToggle" :class="{'border-blue-300': purchasePriceTaxToggle, 'border-yellow-300': !purchasePriceTaxToggle}" class="border-solid border-2 flex items-center px-3 ml-1 cursor-pointer">
                            <span v-if="purchasePriceTaxToggle">Incl. Tax</span>
                            <span v-else>Excl. Tax</span>
                        </div>
                    </div>
                </div>
                <div class="my-2 flex items-center justify-between">
                    <label for="">GST Tax Rate (%)</label>
                    <select class="w-3/5 border-solid border-2 px-3 py-2" v-model="addProductForm.gstTaxRate">
                        <option value="none" selected>None</option>
                        <option value="exmp">Exempted</option>
                        <option value="0">GST @ 0%</option>
                        <option value="0.1">GST @ 0.1%</option>
                        <option value="0.25">GST @ 0.25%</option>
                    </select>
                </div>
            </div>
            <div class="section mt-5">
                <button class="bg-primary color-secondary w-full py-2" @click="addProduct">Add Product</button>
            </div>
        </div>
    </div>
    <teleport to="#endofbody">
        <AddCategoryModal v-if="addCategoryModalVisible" @close="addCategoryModalVisible = false" />
    </teleport>
</template>

<style scoped>
</style>

<script>
import { ref } from 'vue';
import axios from 'axios';
import AddCategoryModal from './AddCategoryModal.vue';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";
import { __getToken } from '../utils/cookie';
import { API_URL } from '../constants/constants';

export default {
    name: 'AddProductModal',
    components: {
        AddCategoryModal
    },
    emits: ['close'],
    setup(props, { emit }) {
        const store = useStore();
        
        const addProductForm = ref({
            itemName: '',
            category: '',
            itemCode: '',
            itemDesc: '',
            stockAmt: '',
            stockUnit: '',
            hsnCode: '',
            salesPrice: '',
            purchasePrice: '',
            gstTaxRate: ''
        });

        const salesPriceTaxToggle = ref(true);
        const purchasePriceTaxToggle = ref(true);
        const addCategoryModalVisible = ref(false);
        const isLoading = ref(false);
        const toast = useToast();

        const addProduct = () => {
            isLoading.value = true;
            // Input Validation
            if(addProductForm.value.itemName.trim() === "") {
                toast.error("Item name cannot be empty", {
                    timeout: 4000
                });
                return
            }
            if(addProductForm.value.category.trim() === "") {
                toast.error("Please select a category", {
                    timeout: 4000
                });
                return
            }
            if(addProductForm.value.stockAmt.trim() === "") {
                toast.error("Stock amount cannot be empty", {
                    timeout: 4000
                });
                return
            }
            if(addProductForm.value.salesPrice.trim() === "") {
                toast.error("Sales price cannot be empty", {
                    timeout: 4000
                });
                return
            }
            if(addProductForm.value.purchasePrice.trim() === "") {
                toast.error("Purchase price cannot be empty", {
                    timeout: 4000
                });
                return
            }
            if(addProductForm.value.gstTaxRate.trim() === "") {
                toast.error("Please select GST tax rate", {
                    timeout: 4000
                });
                return
            }
            
            const productData = {
                itemName: addProductForm.value.itemName, 
                category: addProductForm.value.category,
                itemCode: addProductForm.value.itemCode,
                itemDesc: addProductForm.value.itemDesc,
                stockAmt: parseFloat(addProductForm.value.stockAmt),
                stockUnit: addProductForm.value.stockUnit,
                hsnCode: addProductForm.value.hsnCode,
                salesPrice: parseFloat(addProductForm.value.salesPrice),
                salesPriceTaxIncl: salesPriceTaxToggle.value,
                purchasePrice: parseFloat(addProductForm.value.purchasePrice),
                purchasePriceTaxIncl: purchasePriceTaxToggle.value,
                gstTaxRate: addProductForm.value.gstTaxRate,
            }
            // Add product API call
            axios.post(API_URL+'/product/add',productData,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': __getToken()
                }
            }).then(response => {
                isLoading.value = false;
                // Check if add product API call was successfull
                if(response.status === 200 && response.data.status === 1) {
                    // Add product data to store
                    store.commit('addProduct', productData);

                    toast.success("Product added successfully", {
                        timeout: 2000
                    });
                    // Close add product modal
                    emit('close');
                } else {
                    toast.error(response.data.message, {
                        timeout: 4000
                    });
                    console.log("add product failed")
                    console.log(response.status)
                    console.log(response.data.status)
                    console.log(response.data.message)
                }
            }).catch(err => {
                isLoading.value = false;
                // Check if input format was wrong
                if(err.response.status === 422) {
                    toast.error("Input format is wrong", {
                        timeout: 4000
                    });
                } else {
                    toast.error("Please check console", {
                        timeout: 4000
                    });
                    console.log("Add product error:", err)
                }
            });
        }

        const isNumber = (evt) => {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();;
            } else {
                return true;
            }
        }
        const categories = []
        return {
            emit,
            addProduct,
            salesPriceTaxToggle,
            purchasePriceTaxToggle,
            addProductForm,
            isNumber,
            addCategoryModalVisible,
            categories: store.state.categories
        }
    }
}
</script>