<template>
    <div class="category-container">
        <h1 class="text-3xl font-semibold">Categories</h1>
        <div class="category-toolbar flex mb-2">
            <div class="text-base bg-primary color-secondary ml-auto py-1 px-2 pr-3 rounded-full cursor-pointer" @click="addCategoryModalVisible = true"><i class="fas fa-plus fa-fw mr-1 text-sm"></i><span>Add Category</span></div>
        </div>
        <Table :header="tableHeader" :rows="tableRows"></Table>
    </div>
    <teleport to="#endofbody">
        <AddCategoryModal v-if="addCategoryModalVisible" @close="addCategoryModalVisible = false" />
    </teleport>
</template>

<script>
import AddCategoryModal from './AddCategoryModal.vue';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import Table from './Table/Table.vue';

export default {
    name: 'Category',
    components: { AddCategoryModal, Table },
    setup() {
        const addCategoryModalVisible = ref(false);
        const store = useStore();
        const categories = computed(() => store.state.categories);
        const headerData = [
            {title:'Name', index: 'name'}, 
            {title:'Product Count', index: 'productCount'}, 
        ]
        
        return {
            addCategoryModalVisible,
            tableHeader: headerData,
            tableRows: categories
        }
    },
    created() {
        const store = useStore();
        // Fetch categories
        store.dispatch('loadCatogories')
    }
}
</script>