<template>
    <div class="sidebar-container h-full min-w-max pb-2 flex flex-col">
        <div class="menu-item text-xl px-12 py-2" @click="emit('updateActiveTab', 'create-invoice')" >
            <div class="bg-primary color-secondary cursor-pointer py-2 px-3 my-1 text-lg rounded-full">
                <i class="fas fa-plus fa-fw mr-1 text-sm"></i>
                <span>Create Sales Invoice</span>
            </div>
        </div>
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300" @click="emit('updateActiveTab', 'dashboard')" :class="{ 'bg-green-400': activeTab === 'dashboard' }" ><i class="fas fa-desktop fa-fw mr-3"></i><span>Dashboard</span></div>
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300" @click="emit('updateActiveTab', 'customers')" :class="{ 'bg-green-400': activeTab === 'customers' }" ><i class="fas fa-users fa-fw mr-3"></i><span>Customers</span></div>
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300" @click="emit('updateActiveTab', 'products')" :class="{ 'bg-green-400': activeTab === 'products' }" ><i class="fas fa-box fa-fw mr-3"></i><span>Products</span></div>
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300" @click="emit('updateActiveTab', 'categories')" :class="{ 'bg-green-400': activeTab === 'categories' }" ><i class="fas fa-box fa-fw mr-3"></i><span>Categories</span></div>
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300" @click="emit('updateActiveTab', 'invoices')" :class="{ 'bg-green-400': activeTab === 'invoices' }" ><i class="fas fa-receipt fa-fw mr-3"></i><span>Invoices</span></div>
        
        <div class="menu-item text-xl px-12 py-2 cursor-pointer hover:bg-green-300 mt-auto" @click="logoutUser"><i class="fas fa-sign-out-alt fa-fw mr-3"></i>Sign Out</div>
    </div>
</template>

<style scoped>
.sidebar-container {
    background-color: #adefd1ff;
    color: #00203fff;
    height: calc(100vh - 64px);
}
.sidebar-icon {
    width: 40px
}
</style>
<script>
import { logoutUser } from '../utils/login';

export default {
    name: 'Sidebar',
    props: ['activeTab'],
    setup(props, { emit }) {
        return {
            logoutUser,
            emit
        }
    }
}
</script>