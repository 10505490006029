<template>
    <div class="backdrop fixed top-0 left-0 h-screen w-screen flex justify-center items-center bg-opacity-50 bg-black" @click.self="emit('close')">
        <div class="add-category-modal-container bg-white px-8 py-4 text-base w-1/5">
            <h2 class="text-3xl font-bold">Add Category</h2>
            <hr />
            <div class="section my-2 pt-2">
                <label for="">Category Name <span class="text-red-500">*</span></label>
                <input type="text" placeholder="Enter Category Name" class="border-solid border-2 w-3/5 px-3 py-2 mt-2 w-full" v-model="categoryName" />
                <div v-if="hasError" class="text-lg text-red-500">
                    {{ error }}
                </div>
            </div>
            <div class="section mt-5">
                <button class="bg-primary color-secondary w-full py-2" @click="addCategory">Add Category</button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import { __getToken } from '../utils/cookie';
import { API_URL } from '../constants/constants';
import { useStore } from 'vuex';
import { useToast } from "vue-toastification";

export default {
    name: 'AddCategoryModal',
    emits: ['close'],
    setup(props, { emit }) {
        const categoryName = ref('');
        const loading = ref(false);
        const error = ref('');
        const hasError = ref(false);
        const store = useStore();
        const toast = useToast();

        const addCategory = () => {
            loading.value = true;
            error.value = '';

            axios.post(API_URL+'/category/create',{ 
                name: categoryName.value
            },{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': __getToken()
                }
            }).then(response => {
                loading.value = false;
                // Check if add category API call was successfull
                if(response.status === 200 && response.data.status === 1) {
                    // Add category data to store
                    store.commit('addCategory', categoryName.value);

                    toast.success("Category added successfully", {
                        timeout: 2000
                    });

                    // Close add category modal
                    emit('close');
                } else {
                    hasError.value = true;
                    error.value = response.status.message
                    console.log(response.status)
                    console.log(response.data.status)
                    console.log(response.data.message)
                }
            }).catch(err => {
                hasError.value = true;
                loading.value = false;
                // Check if category name already exists
                if(err.response.status === 422) {
                    error.value = "Category name already exists";
                } else {
                    error.value = "Please check console";
                    console.log("Login error:", err)
                }
            });
        }
        
        return {
            addCategory,
            categoryName,
            emit,
            loading,
            error,
            hasError
        }
    }
}
</script>