<template>
  <div class="home">
    <p>
      Nmae{{ name }}
    </p>
  </div>
</template>

<script>

export default {
  name: 'Home',
  setup() {
    let name = 'mario'
    let age = 30

    return {
      name, 
      age
    }
  }
}
</script>
