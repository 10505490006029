<template>
    <table class="table-container w-full">
        <thead class="max-w-full mb-2">
            <Header :header="header" />
        </thead>
        <tbody>
            <tr v-for="(row, $index) in rows"
                :key="$index"
                class="row"
                >
                <Row :data="row" />
            </tr>
        </tbody>
    </table>
</template>

<style scoped>
    thead {
        background-color: #adefd1ff;
    }
    .row {
        border-bottom: solid 1px lightgrey;
    }
</style>
<script>
import { computed } from 'vue';
import Header from './Header.vue';
import Row from './Row.vue';

export default {
    name: 'Table',
    components: { Header, Row },
    props: ['header', 'rows'],
    setup(props) {
        const rows = computed(() => props.rows);
        // Parse data according to column headers
        var parsedRows = computed(() => rows.value.map(row => {
            let arr = [];
            props.header.forEach(h => {
                arr.push(row[h.index])
            })
            return arr;
        }));

        return {
            header: props.header,
            rows: parsedRows
        }
    }
}
</script>