<template>
    <div class="dashboard-container flex h-full">
        <Sidebar @updateActiveTab="updateActiveTab" :activeTab="activeTab" />
        <div class="dashboard-content-container p-4 w-full">
            <div v-if="activeTab === 'create-invoice'">
                Create Invoice
            </div>
            <div v-else-if="activeTab === 'dashboard'">
                Dashboard
            </div>
            <div v-else-if="activeTab === 'customers'">
                Customers
            </div>
            <div v-else-if="activeTab === 'products'">
                <Products />
            </div>
            <div v-else-if="activeTab === 'categories'">
                <Categories />
            </div>
            <div v-else-if="activeTab === 'invoices'">
                Invoices
            </div>
        </div>
    </div>
</template>

<script>
import { checkLogin } from '../utils/login';
import { useRouter } from 'vue-router';
import Sidebar from '../components/Sidebar.vue';
import { ref } from 'vue';
import Products from '../components/Products.vue';
import Categories from '../components/Categories.vue';

export default {
    name: 'Dasboard',
    components: {Sidebar, Products, Categories},
    setup() {
        const router = useRouter();
        if(!checkLogin()) {
            router.push({name: 'Login'})
        }

        const activeTab = ref('dashboard');

        const updateActiveTab = (tabName) => {
            activeTab.value = tabName
        }

        return {
            updateActiveTab,
            activeTab
        }
    }
}
</script>
