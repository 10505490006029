<template>
    <div class="login-container flex items-center justify-center h-screen w-full">
        <div class="login-form-container text-xl bg-secondary px-16 py-12 color-primary max-w-xl">
            <div class="text-3xl font-semibold mb-3">
                Login
            </div>
            <hr class="hr-primary" />
            <div class="form-item-group my-6 flex items-center">
                <label class="mr-16 flex-1">Email</label>
                <input ref="email" type="text" class="px-3 py-2" />
            </div>
            <div class="form-item-group my-6 flex items-center">
                <label class="mr-16 flex-1">Password</label>
                <input ref="password" type="password" class="px-3 py-2" />
            </div>
            <div v-if="hasError" class="text-lg text-red-500">
                {{ error }}
            </div>
            <div class="form-item-group mt-6">
                <button @click="handleLogin" :disabled="loginLoading" class="bg-primary color-secondary w-full py-2">Login</button>
            </div>
        </div>
    </div>
</template>

<script>
import { checkLogin, login_user } from '../utils/login';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';
import { API_URL } from '../constants/constants';

export default {
    name: 'Login',
    setup() {
        const router = useRouter();
        if(checkLogin()) {
            router.push({name: 'Dashboard'})
        }

        const email = ref('');
        const password = ref('');
        const error = ref('');
        const hasError = ref(false);
        const loginLoading = ref(false);

        const handleLogin = () => {
            loginLoading.value = true;
            error.value = '';
            hasError.value = false;

            axios.post(API_URL+'/auth/login',{
                email: email.value.value, 
                password: password.value.value
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                loginLoading.value = false;
                // Check if login was successfull
                if(response.status === 200 && response.data.status === 1) {
                    var accessToken = response.data.accessToken;
                    var accessTokenExpiry = response.data.accessTokenExpiry;
                    // Set cookie for the user
                    login_user({ accessToken });
                    
                    window.location.reload();
                    // Redirect to dashboard
                    router.push({name: 'Dashboard'});
                } else {
                    hasError.value = true;
                    error.value = response.status.message
                    console.log("login failed")
                    console.log(response.status)
                    console.log(response.data.status)
                    console.log(response.data.message)
                }
            }).catch(err => {
                hasError.value = true;
                loginLoading.value = false;
                // Check if email/password format was wrong
                if(err.response.status === 422) {
                    error.value = "Email/Password format is wrong";
                } else if (err.response.status === 401) { // Check if credentials were wrong
                    error.value = "Wrong Credentials";
                } else {
                    error.value = "Please check console";
                    console.log("Login error:", err)
                }
            });
        }


        return {
            email, 
            password,
            handleLogin,
            hasError,
            error,
            loginLoading
        }
    }
}
</script>
