import { COOKIE_PREFIX } from "../constants/constants";
import { __setCookie, __getCookie, __deleteCookie } from "./cookie";

const checkLogin = () => {
    const token_cookie = __getCookie(COOKIE_PREFIX + "ut");
    
    return (
        token_cookie.cookieExists &&
        token_cookie.cookieValue !== undefined &&
        token_cookie.cookieValue !== null
    );
};

const login_user = ({ accessToken }) => {
    if (accessToken !== undefined) {
        __setCookie(COOKIE_PREFIX + "ut", accessToken, 1, "month");
    }
};

const logoutUser = () => {
    let cookies = document.cookie.split(";");
    cookies.map((cookie) => __deleteCookie(cookie.split("=")[0]));
    localStorage.removeItem("Nocookie");
    // Clear Storage
    localStorage.clear();
    sessionStorage.clear();
    window.location.href='/'
};

export { checkLogin, login_user, logoutUser }