<template>
    <div class="products-container">
        <h1 class="text-3xl font-semibold">Products</h1>
        <div class="products-toolbar flex mb-2">
            <div class="text-base bg-primary color-secondary ml-auto py-1 px-2 pr-3 rounded-full cursor-pointer" @click="addProductModalVisible = true"><i class="fas fa-plus fa-fw mr-1 text-sm"></i><span>Add Product</span></div>
        </div>
        <Table :header="tableHeader" :rows="tableRows"></Table>
    </div>
    <teleport to="#endofbody">
        <AddProductModal v-if="addProductModalVisible" @close="addProductModalVisible = false" />
    </teleport>
</template>

<script>
import AddProductModal from './AddProductModal';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Table from './Table/Table.vue';

export default {
    name: 'Products',
    components: { AddProductModal, Table },
    setup() {
        const addProductModalVisible = ref(false);
        const store = useStore();
        const products = computed(() => store.state.products.map(prod=>({
            ...prod,
            stockAmt: `${prod.stockAmt} (${prod.stockUnit})`,
            purchasePrice: `₹${prod.purchasePrice} (${prod.purchasePriceTaxIncl?'incl. tax':'excl. tax'})`,
            salesPrice: `₹${prod.salesPrice} (${prod.salesPriceTaxIncl?'incl. tax':'excl. tax'})`
        })));

        const headerData = [
            {title:'Name', index: 'itemName'}, 
            {title:'Category', index: 'category'}, 
            {title:'Stock', index: 'stockAmt'}, 
            {title:'Purchase Price', index: 'purchasePrice'}, 
            {title:'Sales Price', index: 'salesPrice'}, 
            {title:'Item Code', index: 'itemCode'}, 
        ];
        
        return {
            addProductModalVisible,
            tableHeader: headerData,
            tableRows: products
        }
    },
    created() {
        const store = useStore();
        // Fetch products from API
        store.dispatch('loadProducts')

        // Fetch categories
        store.dispatch('loadCatogories')
    }
}
</script>