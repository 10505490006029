<template>
    <nav class="text-2xl bg-primary color-secondary w-full">
        <div class="mx-4 px-2 sm:px-6 lg:px-8">
        <div class="relative flex items-center justify-between h-16">
            <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex-shrink-0 flex items-center ">
                <router-link :to="{name:'Home'}">BILLS365</router-link>
            </div>
            </div>
            <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <div class="" v-if="isLoggedIn">
                    <router-link :to="{name:'Dashboard'}" class="px-3 py-2 rounded-md hover:bg-gray-700 hover:text-white">Dashboard</router-link>
                </div>
                <div class="" v-else>
                    <router-link :to="{name:'Register'}" class="px-3 py-2 rounded-md hover:bg-gray-700 hover:text-white">Register</router-link>
                    <router-link :to="{name:'Login'}" class="px-3 py-2 rounded-md hover:bg-gray-700 hover:text-white ml-4">Login</router-link>
                </div>
            </div>
        </div>
        </div>
    </nav>
</template>

<script>
import { checkLogin } from '../utils/login';

export default {
    name: 'Nav',
    setup() {
        return { 
            isLoggedIn: checkLogin()
        }
    }
}
</script>