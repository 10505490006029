<template>
    <tr class="p-2">
        <th v-for="(header) in header"
            :key="header.index"
            class="p-2"
            >
            <td>{{header.title}}</td>
        </th>
    </tr>
</template>

<style scoped>
</style>
<script>

export default {
    name: 'Header',
    props: ['header'],
    setup(props) {
        return {
            header: props.header
        }
    }
}
</script>