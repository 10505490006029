<template>
    <div class="register-container flex items-center justify-center h-screen w-full">
        <div class="register-form-container text-xl bg-secondary px-16 py-12 color-primary max-w-xl">
            <div class="text-3xl font-semibold mb-3">
                Register
            </div>
            <hr class="hr-primary" />
            <div class="form-item-group my-6 flex items-center">
                <label class="mr-16 flex-1">Name</label>
                <input ref="name" type="text" class="px-3 py-2" />
            </div>
            <div class="form-item-group my-6 flex items-center">
                <label class="mr-16 flex-1">Email</label>
                <input ref="email" type="text" class="px-3 py-2" />
            </div>
            <div class="form-item-group my-6 flex items-center">
                <label class="mr-16 flex-1">Password</label>
                <input ref="password" type="password" class="px-3 py-2" />
            </div>
            <div v-if="hasError" class="text-lg text-red-500">
                {{ error }}
            </div>
            <div class="form-item-group mt-6">
                <button @click="handleRegister" :disabled="registerLoading" class="bg-primary color-secondary w-full py-2">Register</button>
            </div>
        </div>
    </div>
</template>

<script>
import { checkLogin } from '../utils/login';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import axios from 'axios';
import { API_URL } from '../constants/constants';

export default {
    name: 'Register',
    setup() {
        const router = useRouter();
        if(checkLogin()) {
            router.push({name: 'Dashboard'});
        }

        const email = ref('');
        const password = ref('');
        const name = ref('');
        const error = ref('');
        const hasError = ref(false);
        const registerLoading = ref(false);

        const handleRegister = () => {
            registerLoading.value = true;
            error.value = '';
            hasError.value = false;

            axios.post(API_URL+'/auth/register',{
                email: email.value.value, 
                password: password.value.value,
                name: name.value.value
            },{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                registerLoading.value = false;
                // Check if registration was successfull
                if(response.status === 201 && response.data.status === 1) {
                    // Open notification
                    // Redirect to login
                    router.push({name:'Login'});
                } else {
                    hasError.value = true;
                    error.value = response.status.message
                    console.log("registration failed")
                    console.log(response.status)
                    console.log(response.data.status)
                    console.log(response.data.message)
                }
            }).catch(err => {
                hasError.value = true;
                registerLoading.value = false;
                // Check if email/password format was wrong
                if(err.response.data) {
                    error.value = err.response.data;
                } else if(err.response.status === 422) {
                    error.value = "Email/Password format is wrong";
                } else {
                    error.value = "Please check console";
                    console.log("Registration error:", err)
                }
            });
        }

        return {
            email, 
            password, 
            name,
            error,
            hasError,
            registerLoading,
            handleRegister
        }
    }
}
</script>
