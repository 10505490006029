<template>
    <Nav />
    <router-view/>
</template>


<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
}

.color-primary {
    color: #00203fff;
}
.color-secondary {
    color: #adefd1ff;
}
.bg-primary {
    background-color: #00203fff;
}
.bg-secondary {
    background-color: #adefd1ff;
}
.hr-primary {
    border-color: #00203fff;
}
</style>

<script>
import Nav from './components/Nav';

export default {
    name: 'App',
    components: {Nav}
}
</script>