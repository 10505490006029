import {createStore} from 'vuex';
import axios from 'axios';
import { API_URL } from '../constants/constants';
import { __getToken } from '../utils/cookie';

export const store = createStore({
    state() {
        return {
            categories: [],
            products: []
        }
    },
    mutations: {
        addCategory(state, categoryName) {
            state.categories.push({name: categoryName})
        },
        addMultipleCategories(state, categories) {
            categories.forEach(category => {
                state.categories.push(category)
            })
        },
        resetCategories(state) {
            state.categories = [];
        },
        addProduct(state, product) {
            state.products.push(product)
        },
        addMultipleProducts(state, products) {
            products.forEach(p => {
                state.products.push(p)
            })
        },
        resetProducts(state) {
            state.products = [];
        }
    },
    actions: {
        loadCatogories({commit}) {
            commit('resetCategories');
            axios.get(API_URL+'/category/',{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': __getToken()
                }
            }).then(response => {
                // Check if API call was successfull
                if(response.status === 200 && response.data.status === 1) {
                    commit('addMultipleCategories', response.data.categories);
                } else {
                    // Error occured in api call
                    console.log(response)
                }
            }).catch(err => {
                console.error(err)
            });
        },
        loadProducts({commit}) {
            commit('resetProducts');
            axios.get(API_URL+'/product/',{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': __getToken()
                }
            }).then(response => {
                // Check if API call was successfull
                if(response.status === 200 && response.data.status === 1) {
                    commit('addMultipleProducts', response.data.products);
                } else {
                    // Error occured in api call
                    console.log(response)
                }
            }).catch(err => {
                console.error(err)
            });
        }
    }
})