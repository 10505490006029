<template>
  <div class="404-container">
    <p>
        404
    </p>
  </div>
</template>

<script>
export default {
  name: 'Page404',
}
</script>
