<template>
    <td v-for="(cell, $index) in rowData"
        :key="$index"
        class="p-2"
        >
        {{cell}}
    </td>
</template>

<style scoped>
</style>
<script>

export default {
    name: 'Row',
    props: ['data'],
    setup(props) {
        return {
            rowData: props.data
        }
    }
}
</script>